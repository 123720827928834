import { Cod } from './Cod';
import { Insurance } from './Insurance';
import { RelatedToAllegro } from './RelatedToAllegro';

export const PocztaPolskaDefaultValues = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  guid: '',
  pakietGuid: '',
  opakowanieGuid: '',
  opis: '',
  relatedToAllegro: RelatedToAllegro,
  numerNadania: '',
  sygnatura: '',
  terminSprawy: '',
  pobranie: Cod,
  ubezpieczenie: Insurance,

  zasadySpecjalne: '',
  masa: '',
  gabaryt: '',
  wartosc: '',
  ostroznie: false,
  numerTransakcjiOdbioru: '',
};
