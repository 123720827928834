export const Cod = {
  clientEntityState: 4,
  tenantId: '',
  id: '',
  sposobPobrania: '',
  kwotaPobrania: '',
  nrb: '',
  tytulem: '',
  sprawdzenieZawartosciPrzesylkiPrzezOdbiorce: false,
};
