export const pocztapolskaAdditionalServices = [
  {
    id: 1,
    serviceId: 'COD',
    name: 'Pobranie',
    description: 'Pobranie za przesyłkę.',
    remark: '',
  },
  {
    id: 2,
    serviceId: 'INSURANCE',
    name: 'Ubezpieczenie',
    description: 'Ubezpieczenie przesyłki.',
    remark: '',
  },
  {
    id: 604,
    serviceId: 'ACKNOWLEDGEMENT',
    name: 'Potwierdzenie odbioru',
    description: '',
    remark: '',
  },
  {
    id: 603,
    serviceId: 'DELIVERY_NOTE',
    name: 'Potwierdzenie doręczenia',
    description: '',
    remark: '',
  },
  {
    id: 3,
    serviceId: 'CAREFULLY',
    name: 'Ostrożnie',
    description: '',
    remark: '',
  },
  {
    id: 114,
    serviceId: 'DELIVERY_DAY',
    name: 'Doręczenie na wskazany dzień',
    description: '',
    remark: '',
  },
  {
    id: 101,
    serviceId: 'DELIVERY_UP_TO_9',
    name: 'Doręczenie do 9:00',
    description: '',
    remark: '',
  },
  {
    id: 105,
    serviceId: 'DELIVERY_UP_TO_12',
    name: 'Doręczenie do 12:00',
    description: '',
    remark: '',
  },
  {
    id: 111,
    serviceId: 'DELIVERY_AFTER_17',
    name: 'Doręczenie po godzinie 17:00',
    description: '',
    remark: '',
  },
  {
    id: 900,
    serviceId: 'NOT_STANDARD_PARCEL',
    name: 'Przesyłka niestandardowa',
    description: '',
    remark: '',
  },
];
